.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.top {
  background: #D62246;
  background-image: 'boxes.png';
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.main-page {
  overflow-x: hidden;
  text-rendering: geometricPrecision;
}

.feature-header {
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navigation {
  width: 90%;
  max-width: 60m;
  height: 6rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.nav-links {
  line-height: 6rem;
  width: 50%;
  min-width: 12rem;
  max-width: 20rem;
  display: flex;
  justify-content: space-between;
}

.feature {
  width: 100%;
  display: flex;
  justify-content: center;
  word-wrap: break-word;
  padding: 3rem 2rem;
}

.ofeature {
  width: 100%;
  display: flex;
  word-wrap: break-word;
  justify-content: center;
  padding: 3rem 2rem;
  background: #dedede;
}

.efeature {
  width: 100%;
  display: flex;
  word-wrap: break-word;
  justify-content: center;
  padding: 3rem 2rem;
}

.end-button {
  background: #D62246;
}